@if (!mainProjectsLoading() && mainProjects().length) {
  <div class="flex-row search-container" flexLayoutAlign="end center" [ngStyle]="{ maxWidth: config.maxWidth }">
    <div>
      <input type="text" style="position: fixed; left: -10000000px" />
      <ngx-q360-search-input
        [testId]="testIds.ProjectListSearch"
        placeholder="Search for main project groups or projects"
        [formControl]="searchControl"
        [projectListSearch]="true"
      />
    </div>
  </div>
}
<div class="flex-row flex">
  <div class="flex-column flex" flex="33.34%">
    <div
      class="flex-row px-16 py-14 bb br"
      flexLayoutAlign="space-between center"
      flexGap="16px"
      [ngStyle]="{ height: '64px' }"
    >
      <div class="labels-label-s" [testId]="testIds.ProjectListMainProjectTitle">
        Main <span [userOnboardingGuideType]="userOnboardingSettingsEnum.ORGANIZE_YOUR_PROJECTS">project group</span>
      </div>
      @if (canCreateMainProject()) {
        <button
          [testId]="testIds.ProjectListCreateMainProject"
          class="secondary-button"
          type="button"
          (click)="onCreateMainProject()"
        >
          New main project group
        </button>
      }
    </div>
    <div class="flex-row br flex py-8" flexLayoutAlign="space-between center">
      <ngx-q360-load-more [ngStyle]="{ margin: '0 auto' }" [show]="mainProjectsLoading()" />
      @if (!mainProjectsLoading()) {
        @if (mainProjects(); as mainProjects) {
          <mat-dialog-content [class]="mainProjects.length ? 'flex-align-self-start' : 'flex-align-self-center'">
            <div #mainProjectsListElement class="flex-column flex px-8">
              @for (
                mainProject of mainProjects | filterMainProject: searchValue() : filteredSearchProjects();
                track mainProject.id;
                let i = $index
              ) {
                <div
                  [testId]="testIds.ProjectListSelectMainProject + '-' + i"
                  class="flex-row px-16 hover br-4 cursor-pointer"
                  flexGap="16px"
                  [class]="mainProject.id"
                  [class.selected]="mainProject.id === selectedMainProject()?.id"
                >
                  <div
                    class="flex-row flex py-18"
                    flexLayoutAlign="start center"
                    flexGap="16px"
                    (click)="selectMainProject(mainProject)"
                  >
                    <div class="labels-label-m greyscale-light-text">{{ mainProject.code }}</div>
                    <div class="labels-label-m greyscale-darkest-grey flex">{{ mainProject.name }}</div>
                    <div>
                      @if (mainProject.mainProjectType === mainProjectType.InHouse) {
                        <div class="labels-label-s">In-House</div>
                      } @else if (mainProject.mainProjectType === mainProjectType.Owner) {
                        <div class="flex-row labels-label-s" flexGap="4px">
                          @if (
                            (
                              mainProject.admins
                              | filter
                                : (mainProject.ownerOrganizationId
                                    ? mainProject.ownerOrganizationId
                                    : mainProject.coOwnerOrganizationId)
                                : ['organizationId']
                            ).length
                          ) {
                            <ngx-q360-avatar
                              [sasUrl]="sasUrl()"
                              size="small"
                              [avatar]="{
                                photoUrl: (mainProject.admins
                                  | filter
                                    : (mainProject.ownerOrganizationId
                                        ? mainProject.ownerOrganizationId
                                        : mainProject.coOwnerOrganizationId)
                                    : ['organizationId'])[0].organizationPhotoUrl,
                                name: (mainProject.admins
                                  | filter
                                    : (mainProject.ownerOrganizationId
                                        ? mainProject.ownerOrganizationId
                                        : mainProject.coOwnerOrganizationId)
                                    : ['organizationId'])[0].organizationName
                              }"
                            />
                            {{
                              (mainProject.admins
                                | filter
                                  : (mainProject.ownerOrganizationId
                                      ? mainProject.ownerOrganizationId
                                      : mainProject.coOwnerOrganizationId)
                                  : ['organizationId'])[0].organizationName
                            }}
                          }
                        </div>
                      } @else {
                        @if (mainProject.ownerOrganizationId) {
                          <div class="flex-row labels-label-s" flexGap="4px">
                            @if (
                              (
                                mainProject.admins
                                | filter
                                  : (mainProject.ownerOrganizationId
                                      ? mainProject.ownerOrganizationId
                                      : mainProject.coOwnerOrganizationId)
                                  : ['organizationId']
                              ).length
                            ) {
                              <ngx-q360-avatar
                                [sasUrl]="sasUrl()"
                                size="small"
                                [avatar]="{
                                  photoUrl: (mainProject.admins
                                    | filter
                                      : (mainProject.ownerOrganizationId
                                          ? mainProject.ownerOrganizationId
                                          : mainProject.coOwnerOrganizationId)
                                      : ['organizationId'])[0].organizationPhotoUrl,
                                  name: (mainProject.admins
                                    | filter
                                      : (mainProject.ownerOrganizationId
                                          ? mainProject.ownerOrganizationId
                                          : mainProject.coOwnerOrganizationId)
                                      : ['organizationId'])[0].organizationName
                                }"
                              />
                              {{
                                (mainProject.admins
                                  | filter
                                    : (mainProject.ownerOrganizationId
                                        ? mainProject.ownerOrganizationId
                                        : mainProject.coOwnerOrganizationId)
                                    : ['organizationId'])[0].organizationName
                              }}
                            }
                          </div>
                        } @else {
                          <div class="flex-row" flexGap="4px">
                            <svg
                              class="svg-16 flex-align-self-center greyscale-idle-grey"
                              svgIcon="framed-help-questionmark"
                            />
                            <div class="labels-label-s">Client missing</div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                  @if (
                    ((userMainProjectRoles() | filter: mainProject.id : ['mainProjectId']).length &&
                      (userMainProjectRoles() | filter: mainProject.id : ['mainProjectId'])[0].isMainProjectAdmin) ||
                    (isUserOrgAdmin() && mainProject.creatorOrganizationId === user()?.organizationId)
                  ) {
                    <div flexLayoutAlign="start center" class="py-18" (click)="onOpenSettings(mainProject)">
                      <svg class="svg-16 primary-colors-accent-blue" svgIcon="utility-settings" />
                    </div>
                  }
                </div>
              } @empty {
                <app-nothing-to-show [loading]="mainProjectsLoading()" [icon]="nothingToShowIcons.Tasks">
                  <div
                    class="paragraph-body text-align-center greyscale-light-text"
                    [testId]="testIds.ProjectListNoMainProject"
                  >
                    @if (searchControl.value) {
                      Nothing found
                    } @else {
                      Main project groups will appear here. Use the “New main project group” button to get started.
                    }
                  </div>
                </app-nothing-to-show>
              }
            </div>
          </mat-dialog-content>
        }
      }
    </div>
  </div>
  <div class="flex-column flex" flex="66.66%">
    <div
      class="flex-row px-16 py-8 bb"
      flexLayoutAlign="space-between center"
      flexGap="16px"
      [ngStyle]="{ height: '64px' }"
    >
      @if (mainProjects().length) {
        <div class="labels-label-s greyscale-light-text flex" [testId]="testIds.ProjectListActiveProjectTitle">
          Active project
        </div>
        @if (showNewProjectButton()) {
          <button
            [testId]="testIds.ProjectListCreateNewProject"
            [disabled]="!selectedMainProject()"
            class="secondary-button"
            type="button"
            (click)="onCreateProject()"
          >
            New project
          </button>
        }
      }
    </div>
    <div class="flex-row py-14 flex" flexLayoutAlign="space-between center">
      <ngx-q360-load-more [ngStyle]="{ margin: '0 auto' }" [show]="mainProjectsLoading()" />

      @if (!mainProjects().length && !mainProjectsLoading()) {
        <div class="flex flex-align-self-center paragraph-body text-align-center greyscale-light-text">
          Active projects you create will appear here.
        </div>
      }

      @if (!mainProjectsLoading() && mainProjects().length) {
        <mat-dialog-content [class]="selectedProjects().length ? 'flex-align-self-start' : 'flex-align-self-center'">
          <div class="flex-column flex px-8">
            @for (
              project of selectedProjects() | filter: searchControl.value : ['name', 'code'];
              track project.id;
              let i = $index
            ) {
              <div
                class="flex-row px-16 py-18 br-4 hover"
                flexGap="16px"
                flexLayoutAlign="center center"
                [class.selected]="project.id === selectedProject()?.id"
              >
                <div
                  class="flex-row flex cursor-pointer"
                  flexGap="16px"
                  flexLayoutAlign="start center"
                  (click)="selectProject(project)"
                  [testId]="testIds.ProjectListSelectActiveProject + '-' + i"
                >
                  <div class="labels-label-m greyscale-light-text">{{ project.code }}</div>
                  <div class="labels-label-m greyscale-darkest-grey">{{ project.name }}</div>
                  @if (project.activeSubscription?.isTrial) {
                    <div class="px-6 py-2 secondary-8-orange-8-bg primary-colors-orange br-4 labels-label-s-bold">
                      Trial
                    </div>
                  }
                </div>
                <div
                  class="flex-row"
                  flexGap="16px"
                  flexLayoutAlign="center center"
                  [ngStyle]="{ lineHeight: 'normal' }"
                >
                  <div class="flex-row greyscale-light-text" flexLayoutAlign="center center" flexGap="4px">
                    <svg class="svg-16" svgIcon="users-users" />
                    <div class="labels-label-s">
                      {{ project.numberOfMembers }}
                    </div>
                  </div>
                  <div class="flex-row greyscale-light-text" flexLayoutAlign="center center" flexGap="4px">
                    <svg class="svg-16" svgIcon="other-tasks" />
                    <div class="labels-label-s">
                      {{ project.numberOfProjectTasks }}
                    </div>
                  </div>
                  <div class="flex-row greyscale-light-text" flexLayoutAlign="center center" flexGap="4px">
                    <svg class="svg-16" svgIcon="other-decisions" />
                    <div class="labels-label-s">
                      {{ project.numberOfDecisions }}
                    </div>
                  </div>
                  <div (click)="onPinProject(project)">
                    @if ((pinnedProjects() | filter: project.id : ['id']).length) {
                      <svg
                        [testId]="testIds.ProjectListUnPinProject + '-' + i"
                        matTooltip="Unpin from navigation"
                        matTooltipClass="icon-tooltip"
                        class="svg-16 pin-icon pinned"
                        svgIcon="utility-pin-filled"
                      />
                    } @else {
                      <svg
                        [testId]="testIds.ProjectListPinProject + '-' + i"
                        matTooltip="Pin to navigation"
                        matTooltipClass="icon-tooltip"
                        class="svg-16 pin-icon"
                        svgIcon="utility-pin"
                      />
                    }
                  </div>
                </div>
              </div>
            } @empty {
              <div
                [testId]="testIds.ProjectListNoActiveProject"
                class="flex-column flex-align-self-center text-align-center"
                flexLayoutAlign="center center"
                [ngStyle]="{ maxWidth: '460px' }"
              >
                <div class="paragraph-body">
                  @if (searchControl.value) {
                    Nothing found
                  } @else if (selectedMainProject()) {
                    <span class="paragraph-body-1-bold">{{ selectedMainProject()?.name }}</span>
                    has no active projects where you are participant. To view all project belonging to this Main project
                    group go to the
                    <span class="primary-colors-accent-blue cursor-pointer" (click)="navigateToOrg()"
                      >Organization</span
                    >
                    tab.
                  } @else {
                    Please select the main project group.
                  }
                </div>
                @if (!searchControl.value && showNewProjectButton()) {
                  <br />
                  <div class="paragraph-body">To create a new project use the “New project” button.</div>
                }
              </div>
            }
          </div>
        </mat-dialog-content>
      }
    </div>
  </div>
</div>
